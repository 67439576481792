<template>
  <div class="news-center">
    <!--面包屑区域-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    新闻列表区域-->
    <div class="news-list">
      <!--      头部区域-->
      <div class="list-title">新闻中心</div>
      <!--      列表区域-->
      <div class="list">
        <div @click="toDetail(items.id)" v-for="(items,key) in news" :key="key" class="listitem">
          <div class="list-contain">
            <!-- <img :src="items.img" alt=""> -->
            <div class="list-contain-right">
              <span class="title">{{items.title}}</span>
              <div class="contain-right-bottom">
                <span class="time">{{items.createtime}}</span>
                <span class="from">{{items.author === '0' ? '' : items.author}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      加载更多按钮-->
      <div class="load-more" @click="loadmore" style="cursor: pointer">加载更多<i class="el-icon-caret-bottom"></i></div>
    </div>
  </div>
</template>

<script>
import { request } from '@/network';
export default {
  name: 'indexNewsCenter',
  data() {
    return {
      news: [],
      page: 1,
      pagesize: 10,
      newsV: []
    };
  },
  created() {
    this.loadNews();
  },
  computed: {
    getnews() {
      return this.news;
    }
  },
  methods: {
    toDetail(id) {
      this.$router.push({
        path: '/newsdetail',
        query: {
          id: id
        }
      });
    },
    async loadNews() {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/index/getNews',
        params: {
          page: this.page,
          pagesize: this.pagesize
        }
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.news = res.data;
    },
    async loadmore() {
      this.page++;
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/index/getNews',
        params: {
          page: this.page,
          pagesize: this.pagesize
        }
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.news.push(...res.data);
    }
  }
};
</script>

<style scoped>
/*面包屑区域样式*/
.news-center {
  background-color: #fff;
  padding: 45px 0px 59px;
}
.el-breadcrumb {
  padding-bottom: 32px;
}
.news-list {
  /* margin: 0px 50px; */
}
/*新闻列表区域*/
.list-title {
  height: 32px;
  padding-bottom: 17px;
  border-bottom: 2px solid #015293;
  font-size: 32px;
  color: #015293;
  text-align: left;
  line-height: 32px;
}
.listitem {
  display: flex;
  align-items: center;
  height: 100px;
  border-bottom: 1px solid #e9eaec;
  cursor: pointer;
}
.list-contain {
  display: flex;
  width: 100%;
  height: 67.5px;
}
.list-contain img {
  height: 67.5px;
  width: 90px;
}
.list-contain-right {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.list-contain-right .title {
  margin-top: 9px;
  font-size: 18px;
  color: #000000;
   display: -webkit-box; 
  -webkit-box-orient: vertical; 
  text-overflow: ellipsis; 
  overflow: hidden; 
  -webkit-line-clamp: 1; 
}
.list-contain-right .contain-right-bottom {
  margin-top: 20px;
  font-size: 14px;
  color: #b8b8b8;
}
.list-contain-right .contain-right-bottom .time {
  margin-right: 28px;
}
.load-more {
  width: 100%;
  height: 40px;
  background: #f3f6f8;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #015293;
}
</style>
